<div class="medications">
  <div
    *ngIf="!isResultPage"
    class="medications_item card appointment"
    (click)="addAppointment()"
  >
    <div class="medications_icon">
      <img src="assets/images/appointment.svg" alt="appointment">
    </div>
    <div class="medications_title">
      {{ 'Жалобы и диагноз' | translate }} {{ isResultPage ? '*' : '' }}
    </div>
    <div class="medications_action">
      <div class="medications_add" *ngIf="!hasAppointment; else appointmentAdded">
        <mat-icon>add</mat-icon>
      </div>
      <ng-template #appointmentAdded>
        <div class="medications_success">
          <mat-icon>done</mat-icon>
        </div>
      </ng-template>
    </div>
  </div>

  <div
    class="medications_item card medication"
    (click)="addMedications()"
  >
    <div class="medications_icon">
      <img src="assets/images/medication.svg" alt="medication">
    </div>
    <div class="medications_title">
      {{ 'Лечение' | translate }}
    </div>
    <div class="medications_action">
      <div class="medications_add" *ngIf="!medications.length; else medicationsAdded">
        <mat-icon>add</mat-icon>
      </div>

      <ng-template #medicationsAdded>
        <div class="medications_success">
          <mat-icon>done</mat-icon>
        </div>
      </ng-template>
    </div>
  </div>

  <div
    class="medications_item card directions"
    (click)="addDirections()"
  >
    <div class="medications_icon">
      <img src="assets/images/directions.svg" alt="directions">
    </div>
    <div class="medications_title">
      {{ 'Направления' | translate }}
    </div>
    <div class="medications_action">
      <div
        class="medications_add"
        *ngIf="!consultation.analyzes && !consultation.referralsExamination && !consultation.referralsSpecialists; else directionsAdded"
      >
        <mat-icon>add</mat-icon>
      </div>

      <ng-template #directionsAdded>
        <div class="medications_success">
          <mat-icon>done</mat-icon>
        </div>
      </ng-template>
    </div>
  </div>
</div>
