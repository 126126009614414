<div class="card appointment">
  <div class="medications_item">
    <div class="medications_icon">
      <img src="assets/images/appointment.svg" alt="appointment">
    </div>
    <div class="medications_title">
      {{ 'Жалобы и диагноз' | translate }}*
    </div>

    <div class="ai-generated" *ngIf="aiGenerated">
      <img src="/assets/images/AI.svg" width="24" height="24" alt="">
      {{ 'Згенеровано ШІ' | translate }}
    </div>
  </div>

  <form [formGroup]="form" class="form">
    <div class="field_set">
      <div class="field">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Жалобы' | translate }}</mat-label>

          <textarea
            matInput
            [maxlength]="maxDiagnosisLength"
            [rows]="4"
            cdkAutosizeMinRows="5"
            formControlName="idea"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Диагноз' | translate }}</mat-label>

          <textarea
            matInput
            [maxlength]="maxDiagnosisLength"
            [rows]="4"
            formControlName="diagnosis"
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            [required]="true"
          ></textarea>

          <mat-error>
            <app-validator-message [field]="form.get('diagnosis')">
            </app-validator-message>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="attention">
      <mat-icon fontSet="material-icons-outlined">info</mat-icon>
      <span>{{ 'Общие рекомендации относятся к лечению в целом. Лекарства можно указать отдельно в разделе "Лечение"' | translate }}</span>
    </div>

    <div class="field recommendation">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Рекомендации' | translate }}</mat-label>

        <textarea
          matInput
          [maxlength]="maxRecommendationLength"
          [rows]="4"
          formControlName="recommendation"
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
        ></textarea>

        <mat-error>
          <app-validator-message [field]="form.get('recommendation')">
          </app-validator-message>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
