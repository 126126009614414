import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {TranslateModule} from '../../../core/pipes/translate/translate.module';
import {
  maxDiagnosisLength, maxRecommendationLength
} from '../../../shared-modules/medications/constants/appointment-form.const';
import {
  ValidatorMessageModule
} from '../../../shared-modules/validator-message/validator-message.module';
import {SharedModule} from '../../../shared/shared.module';

@Component({
  selector: 'app-complaints',
  standalone: true,
  imports: [
    TranslateModule,
    CdkTextareaAutosize,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SharedModule,
    ValidatorMessageModule,
    NgIf,
  ],
  templateUrl: './complaints.component.html',
  styleUrl: './complaints.component.scss'
})
export class ComplaintsComponent {
  @Input() aiGenerated: boolean;
  @Input() form: FormGroup;

  protected readonly maxDiagnosisLength = maxDiagnosisLength;
  protected readonly maxRecommendationLength = maxRecommendationLength;

  constructor() {
  }

}
