<div class="result card" [class.in_modal]="!!offlineConsultationId" *ngIf="consultation">
  <span class="title"
        *ngIf="!consultationFromPreview">{{ 'Результат консультации' | translate }}</span>

  <div class="result_attentions" *ngIf="!consultation?.recommendation_status">
    <mat-icon>error_outline</mat-icon>
    <span class="text-center" *ngIf="!consultationFromPreview; else preview">
      {{ 'Необходимо заполнить результат консультации для ее завершения' | translate }}
    </span>

    <ng-template #preview>
      <span class="text-center">
        {{ 'Необходимо заполнить результат консультации' | translate }}
      </span>
    </ng-template>
  </div>

  <div class="result_form">
    <app-complaints
      [form]="complaintsForm"
      [aiGenerated]="aiGenerated">
    </app-complaints>

    <app-medications-list
      [appointmentWasUpdated]="appointmentWasUpdated"
      [consultation]="consultation"
      [isResultPage]="true"
    ></app-medications-list>
  </div>

  <div class="result_actions">
    <button
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="!recommendationFormData.diagnosis?.length"
    >
      {{ 'Отправить пациенту' | translate }}
    </button>
  </div>
</div>
